import { getPaginationQuery, httpService } from ".";
import axios from "axios";
import { BASE_URL } from "./api";
import { GET_STORED_ACCESS_TOKEN } from "../util/storage";
import { checkStatus } from "./../module/util";
import { saveAs } from "file-saver";
import { date_formatter } from "./../util/dateFormetter";

export async function request_order_number() {
  return await httpService.get(`/order/request-waybill-number`);
}

export async function create_order_doc(data) {
  return await httpService.post("/order/create", data);
}

export async function activate_expired_order(orderId) {
  return await httpService.put(`/order/activate/${orderId}`);
}

/**
 * order searching and filtering
 */
export async function search_and_filter(data) {
  return await httpService.post(
    `/order/search?${getPaginationQuery()}&total=true`,
    data
  );
}

/**
 * Order Image upload
 */
export async function upload_order_image(waybill_number, form_data) {
  return await httpService.post(
    `/order-files/all-image-order/${waybill_number}`,
    form_data
  );
}

/** nyi order upload */
export async function upload_all_order_image(waybill_number, form_data) {
  return await httpService.post(
    `/order-files/all-images-order/${waybill_number}`,
    form_data
  );
}

/**
 * Order fetch image
 */

export async function fetch_order_image(file_name) {
  return await httpService.get(`/order-files/order-single-image/${file_name}`, {
    responseType: "blob",
  });
}

/**
 * Delete order image
 */
export async function delete_order_image(filename, waybillnumber, type) {
  return await httpService.delete(
    `/order-files/order-image-delete-custom?file_name=${filename}&waybill_id=${waybillnumber}&type=${type}`
  );
}

/**
 * Delete order
 */
export async function delete_order(id) {
  return await httpService.delete(`/order/${id}`);
}

export async function delete_multiple_order(waybill_id_list) {
  return await axios.delete(`${BASE_URL}/order`, {
    data: { waybill_id_list },
    headers: { "x-access-token": GET_STORED_ACCESS_TOKEN },
  });
}

export async function export_orders(data) {
  const response = await httpService.post("/order/export-order", data, {
    responseType: "blob",
  });
  if (checkStatus(response)) {
    const blob = await response.data;
    const temp_date = date_formatter();
    saveAs(blob, `orders-${temp_date}.csv`);
  }
}
