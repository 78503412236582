export const checkStatus = (response = {
    status: 401
}) => {
    return response.status === 200 || response.status === 201
}

export const Defautl_pagination_option = {
    total: 0,
    limit: 10,
    page: 1
}