import { httpService } from ".";

export async function get_monthly_data(month, year) {
  return await httpService.get(
    `/graph/get-monthly-data?month=${month}&year=${year}`
  );
}

export async function get_count_data() {
  return await httpService.get("/graph/get-count-data");
}

export async function get_payment_total() {
  return await httpService.get("/graph/get-payment-total");
}

export async function get_all_branch_total() {
  return await httpService.get("/graph/get-all-branch-total");
}
