import {
    start_loading,
    stop_loading,

    set_container_option,
    set_branch_list,
    set_district_list,
    set_itemtype_list,
    set_region_list,
    set_township_list,
    set_payment_type_list,
    set_shipping_type_list,
    set_shipping_mode_list,
    set_interval_type_list,
    set_destination_list,
    set_interval_group,
    set_role_option,
    set_scan_type_option,
    set_problem_option,
    set_sale_person_list,
    set_marketing_person_list,
    set_country_list,
    set_payment_group,
    set_collection_type,
    set_way_option,
    set_position_option,
} from '../reducer.option'
import {
    set_delivery_option
} from '../reducer.document'
import { financeService, informationservice, optionService } from '../../service';
import { checkStatus } from '../util';
import { component_reducer } from '..';

/**
 * branch option list
 */
export const action_fetch_branch_option = (force = false) => {
    return async (dispatch, getState) => {
        const branch = getState()?.option.branch_list || []
        if (!force && Array.isArray(branch) && branch.length > 0) {
            return;
        }

        try {
            const response = await optionService.OptionBranch();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_branch_list(body))
            }

        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * destination
 */
export const action_fetch_destination_option = (force = false) => {
    return async (dispatch, getState) => {
        const destination_list = getState()?.option.destination_list || []
        if (!force && Array.isArray(destination_list) && destination_list.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionDestination();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_destination_list(body));
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error);
            dispatch(stop_loading())
        }
    }
}

/**
 * 
 */
export const action_fetch_country_option = (force = false) => {
    return async (dispatch, getState) => {
        const result = getState()?.option.country_list || []

        if (!force && Array.isArray(result) && result.length > 0) {
            return;
        }

        try {
            const response = await optionService.OptionCountry();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_country_list(body))
            }
        } catch (error) {
            console.log(error)
        }
    }
}

/**
 * region option list
 */
export const action_fetch_region_option = (force = false) => {
    return async (dispatch, getState) => {
        const region = getState()?.option.region_list || []
        if (!force && Array.isArray(region) && region.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionRegion();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_region_list(body))
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * district option list
 */
export const action_fetch_district_option = (region) => {
    return async (dispatch, getState) => {
        dispatch(start_loading());
        dispatch(set_district_list([]))
        dispatch(set_township_list([]))
        if (region)
            try {
                const response = await optionService.OptionDistrict(region);
                if (checkStatus(response)) {
                    const body = await response.data;
                    dispatch(set_district_list(body))
                }
                else dispatch(stop_loading())
            } catch (error) {
                console.log(error)
                dispatch(stop_loading())
            }
    }
}

/**
 * township option list
 */
export const action_fetch_township_option = (district) => {
    return async (dispatch, getState) => {
        dispatch(start_loading());
        dispatch(set_township_list([]))
        if (district)
            try {
                const response = await optionService.OptionTownship(district);
                if (checkStatus(response)) {
                    const body = await response.data;
                    dispatch(set_township_list(body))
                }
                else dispatch(stop_loading())
            } catch (error) {
                console.log(error)
                dispatch(stop_loading())
            }
    }
}

/**
 * itemtype option list
 */
export const action_fetch_itemtype_option = (force = false) => {
    return async (dispatch, getState) => {
        const itemtype_list = getState()?.option.itemtype_list || []
        if (!force && Array.isArray(itemtype_list) && itemtype_list.length > 0) {
            return;
        }
        try {
            const response = await optionService.OptionItemType();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_itemtype_list(body))
            }

        } catch (error) {
            dispatch(stop_loading())
        }
    }
}

/**
 * payment type option list
 */
export const action_fetch_paymenttypes_option = (force = false) => {
    return async (dispatch, getState) => {
        const payment_type_list = getState()?.option.payment_type_list || []
        if (!force && Array.isArray(payment_type_list) && payment_type_list.length > 0) {
            return;
        }

        try {
            const response = await optionService.OptionPaymentType();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_payment_type_list(body))
            }

        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * payment group option list
 */
export const action_fetch_paymentgroup = (force = false) => {
    return async (dispatch, getState) => {
        const payment_group_list = getState()?.option.payment_group || [];
        if (!force && Array.isArray(payment_group_list) && payment_group_list.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionPaymentGroup();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_payment_group(body));
            } else {
                dispatch(stop_loading())
            }
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * express type option list
 * expresstype = shipping type
 * super, normal , fast shipping etc
 */
export const action_fetch_shippingtype_option = (force = false) => {
    return async (dispatch, getState) => {
        const shipping_type_list = getState()?.option.shipping_type_list || []
        if (!force && Array.isArray(shipping_type_list) && shipping_type_list.length > 0) {
            return;
        }
        try {

            let option = getState().option?.shipping_type_list
            if (Array.isArray(option) && option.length) return;

            const response = await optionService.OptionExpressType();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_shipping_type_list(body))
            }

        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * shipping mode
 * by air , by land , by marine etc..
 */
export const action_fetch_shipping_mode_option = (force = false) => {
    return async (dispatch, getState) => {
        const shipping_mode_list = getState()?.option.shipping_mode_list || []
        if (!force && Array.isArray(shipping_mode_list) && shipping_mode_list.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionShippingMode();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_shipping_mode_list(body))
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * interval type option
 * sender area, receiver area
 */
export const action_fetch_interval_type_option = (force = false) => {
    return async (dispatch, getState) => {
        const interval_type_list = getState()?.option.interval_type_list || []
        if (!force && Array.isArray(interval_type_list) && interval_type_list.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionIntervalType();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_interval_type_list(body))
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error)
            dispatch(stop_loading())
        }
    }
}

/**
 * interval group by type
 */
export const action_fetch_interval_group_option = (force = false) => {
    return async (dispatch, getState) => {
        const interval_list_sender = getState()?.option.interval_list_sender || []
        const interval_list_receiver = getState()?.option.interval_list_receiver || []
        if (!force &&
            Array.isArray(interval_list_sender) && interval_list_sender.length > 0
            &&
            Array.isArray(interval_list_receiver) && interval_list_receiver.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionIntervalGroup();
            console.log(response);
            if (checkStatus(response)) {
                const { sending_interval, receiving_interval } = await response.data;
                dispatch(set_interval_group(sending_interval, receiving_interval))
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error);
            dispatch(stop_loading())
        }
    }
}

/**
 * user role option
 */
export const action_fetch_userrole_option = (force = false) => {
    return async (dispatch, getState) => {
        const user_role_list = getState()?.option.user_role_list || []
        if (!force && Array.isArray(user_role_list) && user_role_list.length > 0) {
            return;
        }
        dispatch(start_loading());
        try {
            const response = await optionService.OptionUserRole();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_role_option(body));
            }
            else dispatch(stop_loading())
        } catch (error) {
            console.log(error)
            dispatch(stop_loading());
        }
    }
}

/**
 * sale person option
 */
export const action_fetch_sale_person_option = (force = false) => {
    return async (dispatch, getState) => {
        const sale_person_list = getState()?.option.sale_person_list || []
        if (!force && Array.isArray(sale_person_list) && sale_person_list.length > 0) {
            return;
        }
        try {
            const response = await optionService.OptionSalePerson();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_sale_person_list(body));
            }
        } catch (error) {
            console.log(error);
        }
    }
}


/**
 * sale marketing option
 */
export const action_fetch_marketing_person_option = (force = false) => {
    return async (dispatch, getState) => {
        const marketing_person_list = getState()?.option.marketing_person_list || []
        if (!force && Array.isArray(marketing_person_list) && marketing_person_list.length > 0) {
            return;
        }
        try {
            const response = await optionService.OptionMarketingPerson();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_marketing_person_list(body));
            }
        } catch (error) {
            console.log(error);
        }
    }
}

/**
 * scan type option list
 */

export const action_fetch_scan_type_option = (force = false) => {
    return async (dispatch, getState) => {
        const scan_option = getState()?.option?.scantype_option || []
        if (!force && Array.isArray(scan_option) && scan_option.length > 0) {
            return;
        }

        try {
            const response = await optionService.OptionScanTypelist()
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_scan_type_option(body || []));
            }
        } catch (error) {
            console.log(error);
        }
    }
}

/**
 * problem
 */
export const action_fetch_problem_option = (force = false) => {
    return async (dispatch, getState) => {
        const problem = getState?.option?.problem_type_list || []
        if (!force && Array.isArray(problem) && problem.length > 0) {
            return;
        }

        try {
            const response = await optionService.OptionProblemType()
            if (checkStatus(response)) {
                const body = await response.data
                dispatch(set_problem_option(body || []))
            }

        } catch (error) {
            console.log(error.message)
        }
    }
}


/**
 * options for finance 
 */

export const action_fetch_collection_type_option = (force = false) => {
    return async (dispatch, getState) => {
        try {
            const list = getState()?.option?.collectiontype_list || []

            if (!force && Array.isArray(list) && list.length > 0) {
                return;
            }

            const response = await financeService.getCollectionOption();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_collection_type(body || []))
            }

        } catch (error) {
            console.log(error);
            dispatch(component_reducer.set_snack_bar_content({
                message: error.message,
                type: 'error'
            }))
        }
    }
}

/**
 * options for way
 */
export const action_fetch_way_option = (force = false) => {
    return async (dispatch, getState) => {
        try {
            const list = getState?.option?.way_option || []

            if (!force && Array.isArray(list) && list.length > 0) {
                return;
            }

            const response = await optionService.OptionWayList();

            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_way_option(body || []))
            }
        } catch (error) {
            console.log(error);
            dispatch(component_reducer.set_snack_bar_content({
                message: error.message,
                type: 'error'
            }))
        }
    }
}


/**
 * position
 */
export const action_fetch_position_option = (force = false) => {
    return async (dispatch, getState) => {
        try {
            const list = getState?.option?.position || []

            if (!force && Array.isArray(list) && list.length > 0) {
                return;
            }

            const response = await informationservice.get_position_opton();

            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_position_option(body || []))
            }
        } catch (error) {
            console.log(error);
            dispatch(component_reducer.set_snack_bar_content({
                message: error.message,
                type: 'error'
            }))
        }
    }
}

export const action_fetch_delivery_option = (force = false) => {
    return async (dispatch, getState) => {
        const data = getState().document?.delivery_option || []
        if (!force && Array.isArray(data) && data.length > 0) {
            return;
        }
        const response = await optionService.OptionDelivery();
        if (checkStatus(response)) {
            const body = await response.data;
            dispatch(set_delivery_option(body || []))
        }
    }
}

export const action_fetch_container_option = (force = false) => {
    return async (dispatch, getState) => {
        const data = getState().option?.container_option || []
        if (!force && Array.isArray(data) && data.length > 0) {
            return;
        }
        const response = await optionService.OptionContainer();
        if (checkStatus(response)) {
            const body = await response.data;
            dispatch(set_container_option(body || []))
        }
    }
}

/**
 *? Temporary close [sign waybill]
 */
// export const action_fetch_sign_waybill = () => {
//     return async (dispatch) => {
//         try {
//             const response = await documentService.get_sign_waybill_option();
//             if (checkStatus(response)) {
//                 const body = await response.data;
//                 dispatch(set_sign_waybill_option(body));
//             }
//         } catch (error) {
//             console.log(error);
//             dispatch(component_reducer.set_snack_bar_content({
//                 message: error.message,
//                 type: 'error'
//             }))
//         }
//     }
// }