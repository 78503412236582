import * as common from "./type";

const type = {
  SET_WAYBILL_LIST_BY_CREDIT: "SET_WAYBILL_LIST_BY_CREDIT",
  SET_WAYBILL_LIST_BY_CASH: "SET_WAYBILL_LIST_BY_CASH",
  SET_TRANSACTION_RECORDS_LIST: "SET_TRANSACTION_RECORDS_LIST",
  SET_WAYBILL_LIST_BY_SALE: "SET_WAYBILL_LIST_BY_SALE",
  SET_CURRENT_ROW: "SET_CURRENT_ROW",
  SET_SEARCH_OPTION: "SET_SEARCH_OPTION",
};

export const initialState = {
  isLoading: false,
  errorMessage: "",
  list_by_credit: [],
  list_by_cash: [],
  list_by_sale: [],

  current_row: null,
  searchOption: {},
  transaction_records: [],
};

const _getCommonState = (state) => ({
  ...state,
  isLoading: false,
  errorMessage: "",
});
/**
 * reducer
 */
const Index = (state = initialState, action) => {
  switch (action.type) {
    case common.START_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: true,
      };
    case common.STOP_LOADING:
      return {
        ..._getCommonState(state),
      };
    case common.SET_ERROR_MESSAGE:
      return {
        ..._getCommonState(state),
        errorMessage: action.payload,
      };
    case type.SET_WAYBILL_LIST_BY_CREDIT:
      return {
        ..._getCommonState(state),
        list_by_credit: action.payload,
      };
    case type.SET_WAYBILL_LIST_BY_CASH:
      return {
        ..._getCommonState(state),
        list_by_cash: action.payload,
      };
    case type.SET_WAYBILL_LIST_BY_SALE:
      return {
        ..._getCommonState(state),
        list_by_sale: action.payload,
      };
    case type.SET_CURRENT_ROW:
      return {
        ..._getCommonState(state),
        current_row: action.payload,
      };
    case type.SET_TRANSACTION_RECORDS_LIST:
      return {
        ..._getCommonState(state),
        transaction_records: action.payload,
      };
    case type.SET_SEARCH_OPTION: {
      return {
        ..._getCommonState(state),
        searchOption: action.payload,
      };
    }
    default:
      return state;
  }
};

export default Index;

/**
 * action
 */

export const start_loading = () => ({
  type: common.START_LOADING,
});

export const stop_loading = () => ({
  type: common.STOP_LOADING,
});

export const set_error_message = (message = "") => ({
  type: common.SET_ERROR_MESSAGE,
  payload: message,
});

export const set_list_by_credit = (data = []) => ({
  type: type.SET_WAYBILL_LIST_BY_CREDIT,
  payload: data,
});

export const set_list_by_cash = (data = []) => ({
  type: type.SET_WAYBILL_LIST_BY_CASH,
  payload: data,
});

export const set_list_by_sale = (data = []) => ({
  type: type.SET_WAYBILL_LIST_BY_SALE,
  payload: data,
});

export const set_current_row = (data) => ({
  type: type.SET_CURRENT_ROW,
  payload: data,
});

export const set_transaction_records = (data = []) => ({
  type: type.SET_TRANSACTION_RECORDS_LIST,
  payload: data,
});

export const set_search_option = (data) => ({
  type: type.SET_SEARCH_OPTION,
  payload: data,
});
