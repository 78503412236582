import { financeService } from "../../service";
import {
  set_list_by_cash,
  set_list_by_credit,
  set_list_by_sale,
  set_transaction_records,
} from "../reducer.finance";
import { checkStatus } from "../util";
import { saveAs } from "file-saver";
import {
  set_search_option,
  start_loading,
  stop_loading,
} from "../reducer.document";
import { component_reducer } from "..";
import { date_formatter } from "../../util/dateFormetter";
/**
 * credit list
 */
export const action_fetch_credit_waybill_list = (data) => {
  return async (dispatch, getState) => {
    try {
      let searchOption = {};

      if (data) {
        searchOption = {
          ...getState().document.searchOption,
          ...data,
        };
      }
      dispatch(set_search_option(searchOption));
      const response = await financeService.get_waybill_to_make_purchase({
        payment_group: "credit",
        ...searchOption,
      });
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_list_by_credit(body?.docs || []));
        //setTotalToLocation(body.totalDocs || 0)
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * cash list
 */
export const action_fetch_cash_waybill_list = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      let searchOption = getState().finance.searchOption;

      if (data) {
        searchOption = {
          ...getState().finance.searchOption,
          ...data,
        };
      }

      dispatch(set_search_option(searchOption));

      const response = await financeService.get_waybill_to_make_purchase({
        payment_group: "cash",
        ...searchOption,
      });
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
        dispatch(component_reducer.set_total_sum_row(body["totalSum"] || {}));
        dispatch(set_list_by_cash(body?.docs || []));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const action_fetch_sale_waybill_list = (data) => {
  return async (dispatch, getState) => {
    try {
      const response = await financeService.get_waybill_for_sale(data);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
        dispatch(set_list_by_sale(body?.docs || []));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const action_set_search_option = (data) => {
  return async (dispatch, getState) => {
    dispatch(set_search_option(data));
  };
};

/**
 * search cash list
 */
export const action_search_cash_waybill_list = (bodyData, payment_group) => {
  return async (dispatch, getState) => {
    try {
      const response = await financeService.search_waybill_to_make_purchase(
        bodyData
      );
      if (checkStatus(response)) {
        const body = await response.data;
        if (payment_group === "cash") {
          dispatch(set_list_by_cash(body?.docs || []));
        } else {
          dispatch(set_list_by_credit(body?.docs || []));
        }

        //setTotalToLocation(body.totalDocs || 0)
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const export_finance = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await financeService.export_finance(data);
      if (checkStatus(response)) {
        const blob = await response.data;
        const temp_date = date_formatter();
        saveAs(blob, `${data}-${temp_date}.csv`);
      } else dispatch(stop_loading());
    } catch (error) {
      console.log(error);
      dispatch(stop_loading());
    }
  };
};
export const export_finance_records = () => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await financeService.export_finance_record();
      if (checkStatus(response)) {
        const blob = await response.data;
        const temp_date = date_formatter();
        saveAs(blob, `transaction-${temp_date}.csv`);
      } else dispatch(stop_loading());
    } catch (error) {
      console.log(error);
      dispatch(stop_loading());
    }
  };
};

export const action_fetch_transaction_records = (
  force = false,
  bodyData = {}
) => {
  return async (dispatch, getState) => {
    try {
      let searchOption = getState().finance.searchOption;

      if (bodyData) {
        searchOption = {
          ...searchOption,
          ...bodyData,
        };
      }

      dispatch(set_search_option(searchOption));

      const response = await financeService.get_transaction(
        force,
        searchOption
      );

      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_transaction_records(body["docs"] || []));
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
