import * as common from '../type';

const types = {
    SET_DESTINATION_INVENTORY: 'SET_DESTINATION_INVENTORY',
}

export const initialState = {
    isLoading: false,
    errorMessage: '',
    destination_inventory: []
}

const _getCommonState = (state) => ({
    ...state,
    isLoading: false,
    errorMessage: '',
})

const Index = (state = initialState, action) => {
    switch (action.type) {
        case common.START_LOADING:
            return {
                ..._getCommonState(state),
                isLoading: true,
            }
        case common.STOP_LOADING:
            return {
                ..._getCommonState(state)
            }
        case common.SET_ERROR_MESSAGE:
            return {
                ..._getCommonState(state),
                errorMessage: action.payload
            }
            
        case types.SET_DESTINATION_INVENTORY:
            return {
                ..._getCommonState(state),
                destination_inventory: action.payload
            }
    
        default:
            return state;
    }
}

export default Index;

/**
 * action
 */

 export const start_loading = () => ({
    type: common.START_LOADING
})

export const stop_loading = () => ({
    type: common.STOP_LOADING
})

export const set_error_message = (message = '') => ({
    type: common.SET_ERROR_MESSAGE,
    payload: message
})

/**
 * 
 * Distinction inventory
 */

 export const set_destination_inventory = (data = []) => ({
    type: types.SET_DESTINATION_INVENTORY,
    payload: data
})