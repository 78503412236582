import { component_reducer } from "..";
import { orderService } from "../../service";
import { set_search_option, set_order_query_list } from "../reducer.order";
import { checkStatus } from "../util";

/**
 *
 * @param {*} data
 * @returns
 */
export const action_fliter_search_list = (data) => {
  return async (dispatch, getState) => {
    try {
      let searchOption = {};

      if (data) {
        searchOption = {
          ...getState().order.searchOption,
          ...data,
        };
      }
      dispatch(set_search_option(searchOption));
      const response = await orderService.search_and_filter(searchOption);

      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_order_query_list(body.docs || []));
        dispatch(component_reducer.set_total_sum_row(body["totalSum"] || {}));
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 *
 * @param {*} id
 */
export const action_active_expired_order = (id, order_number) => {
  return async (dispatch, getState) => {
    try {
      const response = await orderService.activate_expired_order(id);
      if (checkStatus(response)) {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Successfully updated for order = ${order_number}`,
            type: "success",
          })
        );
        dispatch(action_fliter_search_list({ table_view: true })); // search data for table view, not for order selection in waybill entry
      } else
        throw new Error("Data is not updated. Please contact to system admin.");
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message || "Something went wrong",
          type: "error",
        })
      );
    }
  };
};

/**
 *
 * @param {*} id
 * @returns
 */
export const action_delete_order = (id) => {
  return async (dispatch, getState) => {
    dispatch(component_reducer.start_component_loading());
    try {
      const response = await orderService.delete_order(id);
      if (checkStatus(response)) {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Successfully deleted waybill`,
            type: "success",
          })
        );
        dispatch(action_fliter_search_list(null));
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Something went wrong`,
            type: "error",
          })
        );
        dispatch(component_reducer.stop_component_loading());
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message || "Something went wrong",
          type: "error",
        })
      );
      dispatch(component_reducer.stop_component_loading());
    }
  };
};

export const action_delete_multiple_order = (waybill_id_list) => {
  return async (dispatch, getState) => {
    try {
      dispatch(component_reducer.start_component_loading());
      const response = await orderService.delete_multiple_order(
        waybill_id_list
      );
      if (checkStatus(response)) {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Successfully deleted waybill`,
            type: "success",
          })
        );

        dispatch(action_fliter_search_list(null));
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Something went wrong`,
            type: "error",
          })
        );
        dispatch(action_fliter_search_list(null));
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.response.data.message || "Something went wrong",
          type: "error",
        })
      );
      dispatch(component_reducer.stop_component_loading());
    }
  };
};
