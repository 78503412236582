import * as common from '../type';

const types = {
    SET_ANNOUNCEMENT: "SET_ANNOUNCEMENT",
}

export const initialState = {
    isLoading: false,
    errorMessage: '',
    announcements: []
}

const _getCommonState = (state) => ({
    ...state,
    errorMessage: '',
    isLoading: false,
})

const Index = (state = initialState, action) => {
    switch (action) {
        case common.START_LOADING:
            return {
                ..._getCommonState(state),
                isLoading: true,
            }
        case common.SET_ERROR_MESSAGE:
            return {
                ...getComputedStyle(state),
                errorMessage: action.payload
            }

        /**
         * Announcement Maintain
         */

        case types.SET_ANNOUNCEMENT:
            return {
                ..._getCommonState(state),
                announcements: action.payload
            }
        default:
            return state;
    }
}

export default Index;

/**
 * Actions
 */

export const start_loading = () => ({
    type: common.START_LOADING
})

export const stop_loading = () => ({
    type: common.STOP_LOADING
})

export const set_error_message = (message = '') => ({
    type: common.SET_ERROR_MESSAGE,
    payload: message
})

/**
 * Announcement maintain
 */

export const set_announcement = (data = []) => ({
    type: types.SET_ANNOUNCEMENT,
    payload: data
})