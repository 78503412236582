import { component_reducer, Document_Action } from "..";
import { documentService } from "../../service";
import {
  set_query_list,
  set_search_option,
  start_loading,
  stop_loading,
  update_query_list,
} from "../reducer.document";
import { checkStatus } from "../util";

import { saveAs } from "file-saver";
import { date_formatter } from "../../util/dateFormetter";

export const action_fetch_waybill_query_list = () => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await documentService.get_way_bill_list();
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_query_list(body.docs || []));
        //setTotalToLocation(body.totalDocs || 0)
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
      } else dispatch(stop_loading());
    } catch (error) {
      console.log(error);
      dispatch(stop_loading());
    }
  };
};

export const action_fliter_search_list = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      let searchOption = getState().document.searchOption;

      if (data) {
        searchOption = {
          ...searchOption,
          ...data,
        };
      }

      dispatch(set_search_option(searchOption));

      const response = await documentService.search_and_filter(searchOption);

      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(update_query_list(body.docs || []));
        dispatch(component_reducer.set_total_sum_row(body["totalSum"] || {}));
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
      } else dispatch(stop_loading());
    } catch (error) {
      console.log(error);
      dispatch(stop_loading());
    }
  };
};

export const action_waybill_export_list = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await documentService.export_waybills(data);
      if (checkStatus(response)) {
        const blob = await response.data;
        const temp_date = date_formatter();
        saveAs(blob, `waybill-${temp_date}.csv`);
      } else dispatch(stop_loading());
    } catch (error) {
      dispatch(stop_loading());
    }
  };
};

export const action_assign_wayBill_query = ({ _id, name }) => {
  return async (dispatch, getState) => {
    dispatch(component_reducer.start_component_loading());
    try {
      const { _id: waybill, waybillnumber } = getState()?.component.temp_data;

      const response = await documentService.assign_to_user({
        waybill_id: waybill,
        person_id: _id,
        person_name: name,
      });
      if (checkStatus(response)) {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Successfully assign the waybill : ${waybillnumber} to  ${name}`,
            type: "success",
          })
        );
        dispatch(
          Document_Action.Action_Way_Bill_Query.action_fetch_waybill_query_list()
        );
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Something went wrong`,
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message || "Something went wrong",
          type: "error",
        })
      );
    }
  };
};

export const action_delete_waybill = (id) => {
  return async (dispatch, getState) => {
    dispatch(component_reducer.start_component_loading());
    try {
      const response = await documentService.delete_waybill(id);
      if (checkStatus(response)) {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Successfully deleted waybill`,
            type: "success",
          })
        );

        dispatch(action_fetch_waybill_query_list());
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Something went wrong`,
            type: "error",
          })
        );
      }
      dispatch(component_reducer.stop_component_loading());
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message || "Something went wrong",
          type: "error",
        })
      );
      dispatch(component_reducer.stop_component_loading());
    }
  };
};

export const action_delete_multiple_waybill = (waybill_id_list) => {
  return async (dispatch, getState) => {
    try {
      dispatch(component_reducer.start_component_loading());
      const response = await documentService.delete_multi_waybill(
        waybill_id_list
      );
      if (checkStatus(response)) {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Successfully deleted waybill`,
            type: "success",
          })
        );

        dispatch(action_fetch_waybill_query_list());
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: `Something went wrong`,
            type: "error",
          })
        );
        dispatch(action_fetch_waybill_query_list());
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.response.data.message || "Something went wrong",
          type: "error",
        })
      );
      dispatch(component_reducer.stop_component_loading());
    }
  };
};
