import { saveAs } from "file-saver";
import { component_reducer, WAYBILL_STATUS } from "..";
import {
  documentService,
  shippingInventoryService,
  cargoService,
} from "../../service";
import {
  set_list_view_item,
  set_way_list,
} from "../reducer.cargo_diversion_center/reducer.cargo_loading";
import {
  set_list_view_item as set_unloading_list_view,
  set_confirmed_way_list,
} from "../reducer.cargo_diversion_center/reducer.cargo_unloading";
import { set_destination_inventory } from "../reducer.cargo_diversion_center/reducer.distinction_inventory";
import { set_shipping_inventory } from "../reducer.cargo_diversion_center/reducer.shipping_inventory";
import {
  set_first_table_row,
  set_second_table_row,
  start_table_refresh,
} from "../reducer.cargo_diversion_center/reducer.truck_loading";
import { checkStatus } from "../util";

import {
  set_search_option,
  start_loading,
  stop_loading,
} from "../reducer.document";
import { set_sign_query_list } from "../reducer.sign";

/**
 *
 * @param {*} force  for state data check
 * @param {*} for_unloading for way list
 * if false , list will get for cargo-loading as default
 * if true  , list will get for cargo-unloading
 * @returns
 */
export const action_fetch_way_list = (
  force = false,
  for_unloading = false,
  data = {}
) => {
  return async (dispatch, getState) => {
    try {
      const respones = await shippingInventoryService.get_all_way(
        force,
        for_unloading,
        data
      );

      if (checkStatus(respones)) {
        const body = await respones.data;

        if (for_unloading) dispatch(set_confirmed_way_list(body));
        else dispatch(set_way_list(body));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const action_fetch_waybill_cargo_list = ({ container }) => {
  return async (dispatch, getState) => {
    try {
      const response = await cargoService.get_cargo_by_way(container?._id);

      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_list_view_item(body["docs"], container));
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error.message ||
            (Array.isArray(error.errors) && error.errors[0]?.msg) ||
            "Oop, something went wrong!",
          type: "error",
        })
      );
    }
  };
};

/**
 * fetch cargo waybill list by status for cargo load
 */
export const action_fetch_cargo_loading_data = (current_way, destination) => {
  return async (dispatch, getState) => {
    /**
     * fetch loaded waybill
     */
    await CommonAction(dispatch, {
      status: WAYBILL_STATUS.LOADED,
      current_way: current_way,
      destination,
    });
  };
};

/**
 * fetch cargo waybill list by status for cargo unload
 */
export const action_fetch_cargo_unloading_data = (current_way, destination) => {
  return async (dispatch, getState) => {
    /**
     * fetch loaded waybill
     */
    await CommonAction(dispatch, {
      status: WAYBILL_STATUS.UNLOADED,
      current_way: current_way,
      destination,
    });
  };
};

/**
 *
 * @param {*} dispatch
 * @param {*} payload {status,current_way_id}
 */
const CommonAction = async (dispatch, payload) => {
  try {
    const response = await documentService.get_cargo_way_bill_list(payload);

    if (checkStatus(response)) {
      const body = await response.data;
      const data = body["docs"];

      switch (payload.status) {
        case WAYBILL_STATUS.CREATED:
        case WAYBILL_STATUS.CONFIRMED:
          dispatch(set_first_table_row(data));
          break;

        case WAYBILL_STATUS.LOADED:
        case WAYBILL_STATUS.UNLOADED:
          dispatch(set_second_table_row(data));
          break;

        default:
          break;
      }
    }
  } catch (error) {
    const size =
      Array.isArray(error.errors) && error.errors.length > 0
        ? error.errors.length - 1
        : null;

    dispatch(
      component_reducer.set_snack_bar_content({
        message:
          error["message"] ||
          (size > -1
            ? error?.errors[size].msg
            : "Oop something went wrong in getting data!"),
        type: "error",
      })
    );
  }
};

/**
 * fetch loaded item
 */
export const action_fetch_loaded_item = (
  current_way,
  status = WAYBILL_STATUS.LOADED
) => {
  return async (dispatch, getState) => {
    try {
      const loaded_response = await documentService.get_cargo_way_bill_list({
        status: status,
        current_way: current_way._id,
        list_for: true,
      });

      if (checkStatus(loaded_response)) {
        const body = await loaded_response.data;
        const docs = body["docs"];
        if (Array.isArray(docs) && docs.length > 0)
          dispatch(set_list_view_item(body["docs"], current_way));
        else {
          dispatch(set_list_view_item([]));
          dispatch(
            component_reducer.set_snack_bar_content({
              message: `No data is available for the way : ${current_way.way_number}`,
              type: "warning",
            })
          );
        }
      } else
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Error occur while fetchin Loaded waybill list",
            type: "error",
          })
        );
    } catch (error) {
      const size =
        Array.isArray(error.errors) && error.errors.length > 0
          ? error.errors.length - 1
          : null;
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error?.message ||
            (size
              ? error?.errors[size].msg
              : "Oop something went wrong in getting data!"),
          type: "error",
        })
      );
    }
  };
};

/**
 * fetch unloaded item
 */
export const action_fetch_unloaded_item = (
  current_way,
  status = WAYBILL_STATUS.UNLOADED
) => {
  return async (dispatch, getState) => {
    try {
      const loaded_response = await documentService.get_cargo_way_bill_list({
        status: status,
        current_way: current_way._id,
        list_for: true,
      });

      if (checkStatus(loaded_response)) {
        const body = await loaded_response.data;
        const docs = body["docs"];
        if (Array.isArray(docs) && docs.length > 0)
          dispatch(set_unloading_list_view(body["docs"], current_way));
        else {
          dispatch(set_list_view_item([]));
          dispatch(
            component_reducer.set_snack_bar_content({
              message: `No data is available for the way : ${current_way.way_number}`,
              type: "warning",
            })
          );
        }
      } else
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Error occur while fetchin Loaded waybill list",
            type: "error",
          })
        );
    } catch (error) {
      const size =
        Array.isArray(error.errors) && error.errors.length > 0
          ? error.errors.length - 1
          : null;
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error?.message ||
            (size
              ? error?.errors[size].msg
              : "Oop something went wrong in getting data!"),
          type: "error",
        })
      );
    }
  };
};

/**
 *
 * @param {*} force
 * @param {*} data
 * @returns
 */
export const export_inventory = (force = false, data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await documentService.export_shippingInvenctory(data);
      if (checkStatus(response)) {
        const blob = await response.data;
        saveAs(blob, "shippinginvenctory.csv");
      } else dispatch(stop_loading());
    } catch (error) {
      console.log(error);
      dispatch(stop_loading());
    }
  };
};

/**
 * for shipping inventory
 */
export const fetch_inventory_waybill = (data, force = false) => {
  return async (dispatch, getState) => {
    try {
      const list = getState()?.shipping_inventory.shipping_inventory || [];

      let searchOption = getState().document.searchOption;

      if (data) {
        searchOption = {
          ...searchOption,
          ...data,
        };
      }

      if (!force && Array.isArray(list) && list.length > 0) {
        return;
      }

      dispatch(set_search_option(searchOption));

      const inventory_response =
        await documentService.get_way_bill_list_shipping_inventory(
          searchOption
        );

      if (checkStatus(inventory_response)) {
        const body = await inventory_response.data;
        dispatch(set_shipping_inventory(body["docs"]));
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
        //setTotalToLocation(body['totalDocs'] || 0)
      }
    } catch (error) {
      const size =
        Array.isArray(error.errors) && error.errors.length > 0
          ? error.errors.length - 1
          : null;
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error?.message ||
            (size
              ? error?.errors[size].msg
              : "Oop something went wrong in getting data!"),
          type: "error",
        })
      );
    }
  };
};

/**
 * for search shipping inventory
 */
export const search_inventory_waybill = (
  force = false,
  bodyData,
  status = "CREATED"
) => {
  return async (dispatch, getState) => {
    try {
      const list = getState()?.shipping_inventory.shipping_inventory || [];

      if (!force && Array.isArray(list) && list.length > 0) {
        return;
      }
      const response = await cargoService.get_inventory_data(bodyData);

      if (checkStatus(response)) {
        const body = await response.data;
        if (status === "CREATED") {
          dispatch(set_shipping_inventory(body));
        } else {
          dispatch(set_destination_inventory(body));
        }
        // dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Oop something went wrong!",
            type: "error",
          })
        );
      }
    } catch (error) {
      const size =
        Array.isArray(error.errors) && error.errors.length > 0
          ? error.errors.length - 1
          : null;
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error?.message ||
            (size
              ? error?.errors[size].msg
              : "Oop something went wrong in getting data!"),
          type: "error",
        })
      );
    }
  };
};

/**
 * for destination inventory
 */
export const fetch_destination_waybill = (data, force = false) => {
  return async (dispatch, getState) => {
    try {
      const list =
        getState()?.distinction_inventory.destination_inventory || [];

      let searchOption = getState().document.searchOption;

      if (data) {
        searchOption = {
          ...searchOption,
          ...data,
        };
      }

      if (!force && Array.isArray(list) && list.length > 0) {
        return;
      }

      dispatch(set_search_option(searchOption));

      const inventory_response =
        await documentService.get_way_bill_list_for_destination_inventory(
          searchOption
        );

      if (checkStatus(inventory_response)) {
        const body = await inventory_response.data;
        dispatch(set_destination_inventory(body["docs"]));
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
        //setTotalToLocation(body['totalDocs'] || 0)
      }
    } catch (error) {
      const size =
        Array.isArray(error.errors) && error.errors.length > 0
          ? error.errors.length - 1
          : null;
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error?.message ||
            (size
              ? error?.errors[size].msg
              : "Oop something went wrong in getting data!"),
          type: "error",
        })
      );
    }
  };
};

/**
 * for sign confirmed waybill
 */
export const fetch_sign_confirmed_waybill = (force = false) => {
  return async (dispatch, getState) => {
    try {
      const list = getState()?.sign.query_list || [];
      if (!force && Array.isArray(list) && list.length > 0) {
        return;
      }
      const response = await documentService.get_cargo_way_bill_list({
        status: WAYBILL_STATUS.SIGNED,
      });

      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_sign_query_list(body["docs"]));
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
        //setTotalToLocation(body['totalDocs'] || 0)
      }
    } catch (error) {
      const size =
        Array.isArray(error.errors) && error.errors.length > 0
          ? error.errors.length - 1
          : null;
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error?.message ||
            (size
              ? error?.errors[size].msg
              : "Oop something went wrong in getting data!"),
          type: "error",
        })
      );
    }
  };
};

/**
 * for truck loading page
 * two button action , for >> and <<
 * @returns []
 * [0] the remaining items of array from incoming array
 * [1] the result array of checked items
 */

const first_table_rows = "first_table_rows";
const second_table_rows = "second_table_rows";

/**
 * button action of first table in cargo-loading
 * @param {*} current_container_id
 * @param {*} statusFor cargo-loading or cargo-unloading
 * @returns
 */
export const first_button_action = (current_container_id = "", statusFor) => {
  return async (dispatch, getState) => {
    if (!current_container_id || ![0, 1].includes(statusFor)) {
      alert(
        "Invalid information. Missing contianer Id or process Id for Cargo Loading, Unloading!"
      );
      return null;
    }

    try {
      const first_table_list = getState().truck_loading[first_table_rows] || [];

      let messageBody = {
        message: "",
        type: "",
      };

      if (Array.isArray(first_table_list) && first_table_list.length > 0) {
        let check_item_ids = [],
          i = 0;
        const size = first_table_list.length;

        for (; i < size; i++) {
          const { _id, checked } = first_table_list[i];
          if (checked === true) check_item_ids.push(_id);
        }

        if (check_item_ids.length < 1) {
          dispatch(
            component_reducer.set_snack_bar_content({
              message: "There is no selected data to update",
              type: "error",
            })
          );
          return;
        }

        let response;

        switch (statusFor) {
          case 0:
            response = await cargoService.cargo_loading({
              waybill_cargo_ids: check_item_ids,
              container_id: current_container_id,
            });
            break;

          case 1:
            response = await cargoService.cargo_unloading({
              waybill_cargo_ids: check_item_ids,
              container_id: current_container_id,
            });
            break;

          default:
            break;
        }

        if (checkStatus(response)) {
          const body = await response.data;
          messageBody = {
            message: body?.message || `Successfully updated`,
            type: "success",
          };
          dispatch(start_table_refresh());
        } else {
          // invalid reponse
          messageBody = {
            message: `There may be some error. Please contact to system admin`,
            type: "error",
          };
        }
      } else {
        // invalid request when there is no selected waybill in table
        messageBody = {
          message: "There is no selected data to update",
          type: "error",
        };
      }
      dispatch(component_reducer.set_snack_bar_content(messageBody));
      return [new Error("Something went wrong!"), null];
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error.message ||
            (Array.isArray(error.errors) && error.errors[0]?.msg) ||
            "Oop, something went wrong!",
          type: "error",
        })
      );
    }
  };
};

/**
 * filter check waybill.
 */
function getCheckedIds(array = []) {
  if (array.length > 0) {
    const filtered_items = array
      .filter((arr) => arr.checked === true)
      .map((arr) => arr._id);
    return filtered_items;
  } else return null;
}

/**
 *
 * @returns
 */
export const delete_cargo_action = (container_id, statusFor) => {
  return async (dispatch, getState) => {
    try {
      if (![0, 1].includes(statusFor)) {
        alert(
          "Invalid information, process Id is required for Cargo Loading, Unloading!"
        );
        return null;
      }

      const table_list = getState().truck_loading[second_table_rows] || [];
      const list = getCheckedIds(table_list) || [];
      if (!list || list.length < 1) {
        return;
      }
      let response;
      switch (statusFor) {
        case 0:
          response = await cargoService.cargo_undo_loading({
            waybill_cargo_ids: list,
            container_id: container_id,
            is_undo_loading: true,
          });
          break;

        case 1:
          response = await cargoService.cargo_undo_unloading({
            waybill_cargo_ids: list,
            container_id: container_id,
          });
          break;

        default:
          break;
      }

      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "Operation Success!",
            type: "Success",
          })
        );
        dispatch(start_table_refresh());
      } else
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Something went wrong!",
            type: "error",
          })
        );
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error.message ||
            (Array.isArray(error.errors) && error.errors[0]?.msg) ||
            "Oop, something went wrong!",
          type: "error",
        })
      );
    }
  };
};

export const action_recall_way = (way_id, way_number) => {
  return async (dispatch, getState) => {
    try {
      const response = await shippingInventoryService.recall_way(way_id);

      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body?.message || `Successfully recall way : ${way_number}`,
            type: "success",
          })
        );

        dispatch(action_fetch_way_list(true));
      }
    } catch (error) {
      const size =
        Array.isArray(error.errors) && error.errors.length > 0
          ? error.errors.length - 1
          : null;
      dispatch(
        component_reducer.set_snack_bar_content({
          message:
            error?.message ||
            (size
              ? error?.errors[size].msg
              : "Oop something went wrong in getting data!"),
          type: "error",
        })
      );
    }
  };
};
