import { combineReducers } from "redux";

import user from "./reducer.user";

/** To make reducer persistance */
import { persistReducer } from "redux-persist";

/** To store local storage */
import storage from "redux-persist/lib/storage";

import information from "./reducer.information";

import option from "./reducer.option";

import announcement_maintain from "./reducer.announcement/reducer.announcement_maintain";

import sign from "./reducer.sign";

import tabbar, * as tabBar_reduer from "./reducer.tabbar";

import document, * as document_reducer from "./reducer.document";

import order, * as order_reducer from "./reducer.order";

import * as cargo_division_center_reducer from "./reducer.cargo_diversion_center";

import system, * as system_reducer from "./reducer.system";

import quotation, * as quotation_reducer from "./reducer.quotation";

import component, * as component_reducer from "./reducer.components";

import finance, * as finance_reducer from "./reducer.finance";

import location, * as location_reducer from "./reducer.location";

import customer_service, * as customer_reducer from "./reducer.customer_services";

import dashboard from "./reducer.dashboard";

/**
 * actions
 */
import * as Document_Action from "./action.document";
import * as Order_Action from './action.order';
import * as User_Action from "./action.user";
import * as Information_Action from "./action.information";
import * as System_Action from "./action.system";
import * as Option_Action from "./action.option";
import * as Cargo_Division_Action from "./action.cargo_division_center";
import * as Finance_Action from "./action.finance";
import * as Location_Action from "./action.location";

const WAYBILL_STATUS = {
  CREATED: "CREATED",
  LOADED: "LOADED",
  UNDO_LOADED: "UNDO_LOADED",
  CONFIRMED: "CONFIRMED",
  UNLOADED: "UNLOADED",
  DELIVERED: "DELIVERED",
  SIGNED: "SIGNED",
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["tabbar"],
};

const rootReducer = combineReducers({
  user: user,
  component: component,
  system: system,
  information: information,
  option: option,
  quotation: quotation,
  document: document,
  order: order,
  tabbar: tabbar,
  announcement_maintain: announcement_maintain,

  sign: sign,

  distinction_inventory: cargo_division_center_reducer.distinction_inventory,
  cargo_loading: cargo_division_center_reducer.cargo_loading,
  cargo_unloading: cargo_division_center_reducer.cargo_unloading,
  shipping_inventory: cargo_division_center_reducer.shipping_inventory,
  truck_loading: cargo_division_center_reducer.TruckLoading,

  finance: finance,
  location: location,
  customer_service: customer_service,
  dashboard: dashboard,
});

export default persistReducer(persistConfig, rootReducer);

export {
  User_Action,
  Information_Action,
  System_Action,
  Option_Action,
  Document_Action,
  Order_Action,
  Cargo_Division_Action,
  Finance_Action,
  Location_Action,
  /**
   * reducers
   */
  tabBar_reduer,
  document_reducer,
  order_reducer,
  component_reducer,
  quotation_reducer,
  cargo_division_center_reducer,
  system_reducer,
  finance_reducer,
  location_reducer,
  WAYBILL_STATUS,
  customer_reducer,
};
