import { httpService } from '.';
import { GET_STORED_LOCALE } from '../util/storage';

export async function OptionCountry(){
    return await httpService.get('/country/get-option');
}

/**
 * Region
 */
export async function OptionRegion() {
    return await httpService.get('/region/get-options')
}

/**
 * District
 */
export async function OptionDistrict(region_id) {
    return await httpService.get(`/district/get-option/${region_id}`)
}

/**
 * Township
 */
export async function OptionTownship(district_id) {
    return await httpService.get(`/townships/getoption?district=${district_id}`)
}

/**
 * Branch
 */
export async function OptionBranch() {
    return await httpService.get(`/branch/get-option`)
}

/**
 * destination
 */
export async function OptionDestination() {
    return await httpService.get(`/destinations/getoption`)
}

/**
 * express type
 */
export async function OptionExpressType() {
    return await httpService.get(`/shipping-type/get-option`)
}

/**
 * shipping mode
 */
export async function OptionShippingMode() {
    return await httpService.get(`/shipping-mode/get-option`)
}

/**
 * payment type
 */
export async function OptionPaymentType() {
    return await httpService.get(`/payment-type/get-option`)
}

/**
 * payment group
 */
export async function OptionPaymentGroup() {
    return await httpService.get('/payment-type/get-payment-group');
}

/**
 * itemtype
 */
export async function OptionItemType() {
    return await httpService.get(`/itemtypes/get-option`)
}

/**
 * interval type
 */
export async function OptionIntervalType() {
    return await httpService.get('/quotations/getintervaltypes')
}

/**
 * interval group by type
 */
export async function OptionIntervalGroup() {
    return await httpService.get('/quotations/get-interval-group')
}

/**
 * user role option
 */
export async function OptionUserRole() {
    return await httpService.get('/user-roles/get-option')
}

/**
 * sale person option
 */
export async function OptionSalePerson() {
    return await httpService.get('/employee/get-sale-person-option')
}

/**
 * sale marketing option
 */
 export async function OptionMarketingPerson() {
    return await httpService.get('/employee/get-marketing-person-option')
}

/**
 * scan option list
 */
export async function OptionScanTypelist() {
    return await httpService.get('/scan/get-scan-option');
}

/**
 * problem type
 */
export async function OptionProblemType() {
    return await httpService.get('/problems/get-option');
}

/**
 * truck util option
 */
export async function OptionTruckUtil() {
    return await httpService.get(`/truck-and-way/get-util-option`);
}

export async function OptionTruckList() {
    return await httpService.get('/truck/get-option')
}

/**
 * Way opotion
 */
export async function OptionWayList() {
    return await httpService.get('/way/get-option');
}

/**
 * delivery option
 */
export async function OptionDelivery() {
    return await httpService.get(`/waybill/delivery-option?lang=${GET_STORED_LOCALE}`)
}

/**
 * contiainer for cargo loading-unloading
 */
export async function OptionContainer(){
    return await httpService.get(`/container/get-option`)
}