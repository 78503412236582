export const types = {
  SET_COST_DATA: "SET_COST_DATA",
  SET_BALANCE_DATA: "SET_BALANCE_DATA",
  SET_DOUGHNUT_DATA: "SET_DOUGHNUT_DATA",
  SET_BAR_DATA: "SET_BAR_DATA",
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  SET_COUNT_DATA: "SET_COUNT_DATA",
};

const initialState = {
  cost_data: [],
  balance_data: [],
  doughnut_data: [],
  count_data: [],
  errorMessage: "",
  isLoading: false,
  bar_data: [],
};

const _getCommonState = (state) => ({
  ...state,
  isLoading: false,
  errorMessage: "",
});

/**
 * Reducer
 */
const Index = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COST_DATA:
      return {
        ..._getCommonState(state),
        cost_data: action.payload,
      };

    case types.SET_BALANCE_DATA:
      return {
        ..._getCommonState(state),
        balance_data: action.payload,
      };

    case types.SET_DOUGHNUT_DATA:
      return {
        ..._getCommonState(state),
        doughnut_data: action.payload,
      };

    case types.START_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: true,
      };

    case types.STOP_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: false,
      };

    case types.SET_ERROR_MESSAGE:
      return {
        ..._getCommonState(state),
        errorMessage: action.payload,
      };

    case types.SET_COUNT_DATA:
      return {
        ..._getCommonState(state),
        count_data: action.payload,
      };

    case types.SET_BAR_DATA:
      return {
        ..._getCommonState(state),
        bar_data: action.payload,
      };
    default:
      return state;
  }
};

/**
 * Action
 */

export const set_cost_data = (data = []) => {
  return {
    type: types.SET_COST_DATA,
    payload: data,
  };
};

export const set_balance_data = (data = []) => {
  return {
    type: types.SET_BALANCE_DATA,
    payload: data,
  };
};

export const set_doughnut_data = (data = []) => {
  return {
    type: types.SET_DOUGHNUT_DATA,
    payload: data,
  };
};

export const set_error_message = (message = "") => {
  return {
    type: types.SET_ERROR_MESSAGE,
    payload: message,
  };
};

export const start_loading = () => {
  return {
    type: types.START_LOADING,
  };
};

export const stop_loading = () => {
  return {
    type: types.STOP_LOADING,
  };
};

export const set_count_data = (data = []) => {
  return {
    type: types.SET_COUNT_DATA,
    payload: data,
  };
};

export const set_bar_data = (data = []) => {
  return {
    type: types.SET_BAR_DATA,
    payload: data,
  };
};

export default Index;
