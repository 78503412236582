const types = {
    SET_COUNTRY_LIST: "SET_COUNTRY_LIST",
    SET_REGION_LIST: "SET_REGION_LIST",
    SET_DISTRICT_LIST: "SET_DISTRICT_LIST",
    SET_TOWNSHIP_LIST: "SET_TOWNSHIP_LIST",
}

export const initialState = {
    isLoading: false,
    errorMessage: '',
    country_list: [],
    region_list: [],
    district_list: [],
    township_list: []
}

/**
 * reducer
 */
const Index = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_COUNTRY_LIST:
            return {
                ..._getCommonState(state),
                country_list: action.payload
            }

        case types.SET_REGION_LIST:
            return {
                ..._getCommonState(state),
                region_list: action.payload
            }

        case types.SET_DISTRICT_LIST:
            return {
                ..._getCommonState(state),
                district_list: action.payload
            }

        case types.SET_TOWNSHIP_LIST:
            return {
                ..._getCommonState(state),
                township_list: action.payload
            }

        default:
            return {
                ..._getCommonState(state)
            }
    }
}

export default Index;


const _getCommonState = (state) => ({
    ...state,
    isLoading: false,
    errorMessage: ''
})

/**
 * action
 */
export const set_country_list = (data = []) => ({
    type: types.SET_COUNTRY_LIST,
    payload: data
})


export const set_region_list = (data = []) => ({
    type: types.SET_REGION_LIST,
    payload: data
})

export const set_district_list = (data = []) => ({
    type: types.SET_DISTRICT_LIST,
    payload: data
})

export const set_township_list = (data = []) => ({
    type: types.SET_TOWNSHIP_LIST,
    payload: data
})