import * as common from './type';
import _ from 'lodash';

const types = {
    SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
    SET_ORDER_LIST: 'SET_ORDER_LIST',
    SET_CURRENT_ORDER: "SET_CURRENT_ORDER",
    ORDER_TOGGLE_TO_INDEX: "ORDER_TOGGLE_TO_INDEX",
    ORDER_TOGGLE_ALL_LIST: "ORDER_TOGGLE_ALL_LIST",
    SET_ORDER_SEARCH_OPTION: "SET_ORDER_SEARCH_OPTION",
    SHOW_ORDER_EDIT_DIALOG: "SHOW_ORDER_EDIT_DIALOG",
    SET_SELECTED_ORDER: "SET_SELECTED_ORDER",
}  

export const initialState = {
    isLoading: false,
    errorMessage: '',

    order_query_list: [],
    current_order: null,
    showEditDialog: false,
    searchOption: {},
    selected_order: null,

}

const Index = (state = initialState, action) => {
    switch (action.type) {
        case common.START_LOADING:
            return {
                ..._getCommonState(state),
                isLoading: true
            }
        case common.STOP_LOADING:
            return {
                ..._getCommonState(state)
            }
        case common.SET_ERROR_MESSAGE:
            return {
                ..._getCommonState(state),
                errorMessage: action.payload
            }
        case types.SET_ORDER_LIST:
            return {
                ..._getCommonState(state),
                order_query_list: action.payload
            }

        /**
         * TOGGLE TO INDEX
         */
        case types.ORDER_TOGGLE_TO_INDEX:
            try {
                const { index, status, field } = action

                if (!field)
                    throw new Error('Invalid field for cargo action waybill list');

                const dump = _.cloneDeep(state[field]) || [];
                let dump_item = _.cloneDeep(dump[index]);

                dump_item = {
                    ...dump_item,
                    checked: status
                }

                dump[index] = dump_item

                const temp = {
                    ..._getCommonState(state)
                }

                temp[field] = dump;

                return temp

            } catch (error) {
                return {
                    ..._getCommonState(state),
                }
            }


        /**
         * TOGGLE ALL LIST
         */
        case types.ORDER_TOGGLE_ALL_LIST:
            try {
                const { status, field } = action

                if (!field)
                    throw new Error('Invalid field for cargo action waybill list')

                const dump = _.cloneDeep(state[field]) || [];

                if (!Array.isArray(dump))
                    return { ..._getCommonState(state) }

                if (dump.length === 0)
                    return { ..._getCommonState(state) }

                let updated_list = dump.map(d => ({
                    ...d,
                    checked: status
                }))

                const temp = {
                    ..._getCommonState(state)
                }

                temp[field] = updated_list;

                return temp

            } catch (error) {
                return {
                    ..._getCommonState(state),
                }
            }

        case types.SET_CURRENT_ORDER:
            if (action.payload)
                return {
                    ..._getCommonState(state),
                    current_order: action.payload,
                    showEditDialog: true,
                }
            else return {
                ..._getCommonState(state),
                current_order: null,
                showEditDialog: false,
            }

        case types.SET_ORDER_SEARCH_OPTION: {

            return {
                ..._getCommonState(state),
                searchOption: action.payload
            }
        }
        case types.SET_SELECTED_ORDER: {
            return {
                ..._getCommonState(state),
                selected_order: action.payload
            }
        }
        default:
            return state
    }
}

const _getCommonState = (state) => ({
    ...state,
    errorMessage: '',
})

export default Index;

export const set_error_message = (message = '') => ({
    type: common.SET_ERROR_MESSAGE,
    payload: message
})

export const set_order_query_list = (data = []) => ({
    type: types.SET_ORDER_LIST,
    payload: data
})


/**
 * update progress status to item 
 */
export const toggle_to_index = ({ index, status, field }) => ({
    type: types.ORDER_TOGGLE_TO_INDEX,
    index,
    status,
    field
})

export const toggle_to_list = (status, field) => ({
    type: types.ORDER_TOGGLE_ALL_LIST,
    status,
    field
})

export const set_search_option = (data) => ({
    type: types.SET_ORDER_SEARCH_OPTION,
    payload: data
})

export const set_current_order = (data = null) => ({
    type: types.SET_CURRENT_ORDER,
    payload: data
})

export const set_selected_order = (data = null) => ({
    type: types.SET_SELECTED_ORDER,
    payload: data
})