import {
    set_country_list,
    set_region_list,
    set_district_list,
    set_township_list
} from '../reducer.location';
import { locationService } from '../../service';
import { checkStatus } from '../util';

/**
 * fetch country list
 * @param {*} force boolean to fetch data by force or not
 * @returns 
 */
export const action_fetch_country_list = (force = false) => {
    return async (dispatch, getState) => {
        const list = getState()?.location?.country_list || []

        if (!force && Array.isArray(list) && list.length > 0) {
            return;
        }

        try {
            const response = await locationService.getCountry(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_country_list(body))
            }
        } catch (error) {
            console.log(error.message)
        }
    }
}

/**
 * fetch region list
 * @param {*} force boolean to fetch data by force or not
 * @returns 
 */
 export const action_fetch_region_list = (force = false) => {
    return async (dispatch, getState) => {
        const list = getState()?.location?.region_list || []

        if (!force && Array.isArray(list) && list.length > 0) {
            return;
        }

        try {
            const response = await locationService.getRegion(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_region_list(body))
            }
        } catch (error) {
            console.log(error.message)
        }
    }
}

/**
 * fetch district list
 * @param {*} force boolean to fetch data by force or not
 * @returns 
 */
 export const action_fetch_district_list = (force = false) => {
    return async (dispatch, getState) => {
        const list = getState()?.location?.district_list || []

        if (!force && Array.isArray(list) && list.length > 0) {
            return;
        }

        try {
            const response = await locationService.getDistrict(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_district_list(body.docs || []))
            }
        } catch (error) {
            console.log(error.message)
        }
    }
}


/**
 * fetch country list
 * @param {*} force boolean to fetch data by force or not
 * @returns 
 */
 export const action_fetch_township_list = (force = false) => {
    return async (dispatch, getState) => {
        const list = getState()?.location?.township_list || []

        if (!force && Array.isArray(list) && list.length > 0) {
            return;
        }

        try {
            const response = await locationService.getTownship(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_township_list(body))
            }
        } catch (error) {
            console.log(error.message)
        }
    }
}