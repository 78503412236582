import * as common from "../type";

const types = {
  SET_CARGO_LOADING: "SET_CARGO_LOADING",
  SET_WAY_LIST: "SET_WAY_LIST",
  SET_CURRENT_WAY: "SET_CURRENT_WAY",

  /**
   * list view popup dialog
   */
  SET_LIST_VIEW_ITEM: "SET_LIST_VIEW_ITEM",
};

export const initialState = {
  isLoading: false,
  errorMessage: "",
  cargo_loading: [],
  way_list: [],

  /**
   * for list view popup dialog
   */
  list_view_item: [],
  show_list_view_dialog: false,

  current_way: null,
  show_confirm_dialog: false,
};

const _getCommonState = (state) => ({
  ...state,
  isLoading: false,
  errorMessage: "",
});

const Index = (state = initialState, action) => {
  switch (action.type) {
    case common.START_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: true,
      };
    case common.STOP_LOADING:
      return {
        ..._getCommonState(state),
      };
    case common.SET_ERROR_MESSAGE:
      return {
        ..._getCommonState(state),
        errorMessage: action.payload,
      };

    /**
     * Cargo loading
     */
    case types.SET_CARGO_LOADING:
      return {
        ..._getCommonState(state),
        cargo_loading: action.payload,
      };

    case types.SET_WAY_LIST:
      return {
        ..._getCommonState(state),
        way_list: action.payload,
      };

    case types.SET_CURRENT_WAY:
      if (action.payload)
        return {
          ..._getCommonState(state),
          current_way: action.payload,
          show_confirm_dialog: true,
        };
      else
        return {
          ..._getCommonState(state),
          current_way: null,
          show_confirm_dialog: false,
        };

    case types.SET_LIST_VIEW_ITEM:
      const list = action.payload;
      if (Array.isArray(list) && list.length > 0)
        return {
          ..._getCommonState(state),
          list_view_item: action.payload,
          current_way: action.current_way,
          show_list_view_dialog: true,
        };
      else
        return {
          ..._getCommonState(state),
          list_view_item: [],
          current_way: null,
          show_list_view_dialog: false,
        };
    default:
      return state;
  }
};

export default Index;

/**
 * action
 */

export const start_loading = () => ({
  type: common.START_LOADING,
});

export const stop_loading = () => ({
  type: common.STOP_LOADING,
});

export const set_error_message = (message = "") => ({
  type: common.SET_ERROR_MESSAGE,
  payload: message,
});

/**
 *
 * Cargo Loading
 */

export const set_cargo_loading = (data = []) => ({
  type: types.SET_CARGO_LOADING,
  payload: data,
});

/**
 * way list
 */
export const set_way_list = (data = []) => ({
  type: types.SET_WAY_LIST,
  payload: data,
});

export const set_current_way = (data) => ({
  type: types.SET_CURRENT_WAY,
  payload: data,
});

/**
 * update status
 */

/** to each item by index */
export const update_item_status = (index, status, field) => ({
  type: types.UPDATE_ITEM_STATUS,
  index,
  status,
  field,
});

/** to each item by index */
export const update_status_to_all_item = (status, field) => ({
  type: types.UPDATE_STATUS_TO_ALL_ITEM,
  status,
  field,
});

/**
 * show list view dialog
 */
export const set_list_view_item = (data = [], current_way) => ({
  type: types.SET_LIST_VIEW_ITEM,
  payload: data,
  current_way,
});
