import { httpService } from ".";

/**
 *
 * @param {*} force
 * @param {*} payload
 * {
 *  started_way : Boolean
 *  if(true) will return confirmed ways
 *  else will return loading ways
 * }
 * @returns
 */
export async function get_all_way(force, for_unloading, data) {
  return await httpService.post(
    `/way/get-all${force ? "-refresh" : ""}${
      for_unloading ? "?started_way=1" : ""
    }`,
    data
  );
}

export async function create_train_way(data) {
  return await httpService.post("/way/create", data);
}

/**
 * confirm way
 */
export async function confirm_way(way_id) {
  if (!way_id) return;
  return await httpService.get(`/way/confirm/${way_id}`);
}

export async function recall_way(way_id) {
  if (!way_id) return;
  return await httpService.get(`/way/re-call/${way_id}`);
}

/**
 * end way
 */
export async function end_way(way_id) {
  if (!way_id) return;
  return await httpService.get(`/way/end-way/${way_id}`);
}

/**
 * cargo action
 */

export async function common_waybill_action(endpoint, data) {
  return await httpService.post(`/way/${endpoint}`, data);
}

export async function common_cargo_action(data) {
  return await httpService.post(`/way/common-cargo-action`, data);
}

/**
 * search shipping inventory
 */
export async function search_shipping_inventory(data) {
  return await httpService.post(
    "/waybill/search-waybill-for-cargo-action",
    data
  );
}
