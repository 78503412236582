import * as common from '../type';

const types = {
    SET_CARGO_UNLOADING: 'SET_CARGO_UNLOADING',

    SET_CONFIRMED_WAY_LIST: 'SET_CONFIRMED_WAY_LIST',
    SET_CURRENT_WAY_UNLOAD: 'SET_CURRENT_WAY_UNLOAD',

    SET_WAYBILL_LIST_TO_UNLOAD: 'SET_WAYBILL_LIST_TO_UNLOAD',
    SET_UNLOADED_WAYBILL_LIST: 'SET_UNLOADED_WAYBILL_LIST',

    /**
    * list view popup dialog
    */
    SET_LIST_VIEW_ITEM: 'SET_LIST_VIEW_ITEM'
}

export const initialState = {
    isLoading: false,
    errorMessage: '',
    cargo_unloading: [],

    confirmed_way_list: [],
    item_to_unload: [],
    unloaded_item: [],

    /**
     * for list view popup dialog
     */
    list_view_item: [],
    show_list_view_dialog: false,

    current_way: null,
    show_confirm_dialog: false,

}

const _getCommonState = (state) => ({
    ...state,
    isLoading: false,
    errorMessage: ''
});

const Index = (state = initialState, action) => {
    switch (action.type) {
        case common.START_LOADING:
            return {
                ..._getCommonState(state),
                isLoading: true
            }
        case common.STOP_LOADING:
            return {
                ..._getCommonState(state)
            }
        case common.SET_ERROR_MESSAGE:
            return {
                ..._getCommonState(state),
                errorMessage: action.payload
            }

        /**
         * Cargo Unloading
         */
        case types.SET_CARGO_UNLOADING:
            return {
                ..._getCommonState(state),
                cargo_loading: action.payload
            }

        case types.SET_CONFIRMED_WAY_LIST:
            return {
                ..._getCommonState(state),
                confirmed_way_list: action.payload
            }

        case types.SET_WAYBILL_LIST_TO_UNLOAD:
            return {
                ..._getCommonState(state),
                item_to_unload: action.payload
            }
        case types.SET_UNLOADED_WAYBILL_LIST:
            return {
                ..._getCommonState(state),
                unloaded_item: action.payload
            }

        case types.SET_CURRENT_WAY_UNLOAD:
            if (action.payload)
                return {
                    ..._getCommonState(state),
                    current_way: action.payload,
                    show_confirm_dialog: true
                }
            else
                return {
                    ..._getCommonState(state),
                    current_way: null,
                    show_confirm_dialog: false
                }


        case types.SET_LIST_VIEW_ITEM:
            const list = action.payload
            if (Array.isArray(list) && list.length > 0)
                return {
                    ..._getCommonState(state),
                    list_view_item: action.payload,
                    current_way: action.current_way,
                    show_list_view_dialog: true
                }
            else
                return {
                    ..._getCommonState(state),
                    list_view_item: [],
                    current_way: null,
                    show_list_view_dialog: false
                }
        default:
            return state;
    }
}

export default Index;

/**
 * action
 */

export const start_loading = () => ({
    type: common.START_LOADING
})

export const stop_loading = () => ({
    type: common.STOP_LOADING
})

export const set_error_message = (message = '') => ({
    type: common.SET_ERROR_MESSAGE,
    payload: message
})

/**
 * 
 * Cargo Unloading
 */

export const set_cargo_unloading = (data = []) => ({
    type: types.SET_CARGO_UNLOADING,
    payload: data
})

/**
 * way list
 */
export const set_confirmed_way_list = (data = []) => ({
    type: types.SET_CONFIRMED_WAY_LIST,
    payload: data
})

export const set_current_way = (data) => ({
    type: types.SET_CURRENT_WAY_UNLOAD,
    payload: data
})

export const set_item_to_unload = (data = []) => ({
    type: types.SET_WAYBILL_LIST_TO_UNLOAD,
    payload: data
})

export const set_unloaded_waybill = (data = []) => ({
    type: types.SET_UNLOADED_WAYBILL_LIST,
    payload: data
})


/**
 * show list view dialog
 */
 export const set_list_view_item = (data = [],current_way) => ({
    type: types.SET_LIST_VIEW_ITEM,
    payload: data,
    current_way
})