import {
    set_error_message,
    start_loading,
    stop_loading,
    set_container_list,
    set_item_types,
    set_express_types,
    set_carriers,
    set_payment_methods,
    set_problem_types,
    set_shipping_mode,
    set_collection_type,
    set_currency,
} from '../reducer.information';
import { informationservice } from '../../service';
import { checkStatus } from '../util';
import { component_reducer } from '..';

export const action_fetch_item_types = (force = false) => {
    return async (dispatch, getState) => {

        const result = getState()?.information.itemtypes || []
        /**
         * if data exist() 
         * do nothing and return
         */
        if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getItemTypes(force);
            if (checkStatus(response)) {
                const body = await response.data
                dispatch(set_item_types(body));
            }
            else dispatch(stop_loading())
        } catch (error) {
            dispatch(set_error_message(error.messsage));
        }
    }
}

/**
 * Item type delete
 */
export const action_delete_item_type = (id) => {
    return async (dispatch) => {
        dispatch(start_loading());
        try{
            const response = await informationservice.delete_item_type(id);
            if(checkStatus(response)){
                const body = await response.data;
                console.log(body);
                dispatch(action_fetch_item_types(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Successfully deleted ' + body['data']?.name,
                    type: 'success'
                }))
            }else{
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}

export const action_fetch_container = (force = false) => {
    return async (dispatch, getState) => {

        const result = getState()?.information.container_list || []
        /**s
         * if data exist() 
         * do nothing and return
         */
        if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getContainers(force);
            if (checkStatus(response)) {
                const body = await response.data
                dispatch(set_container_list(body));
            }
            else dispatch(stop_loading())
        } catch (error) {
            dispatch(set_error_message(error.messsage));
        }
    }
}




export const action_fetch_express_types = (force = false) => {
    return async (dispatch, getState) => {
        // const result = getState()?.information.expresstypes || []
        /**
         * if data exist() 
         * do nothing and return
         */
        // if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getExpressTypes(force);
            if (checkStatus(response)) {
                const body = await response.data;

                dispatch(set_express_types(body));
            }
            else dispatch(stop_loading());
        } catch (error) {
            dispatch(set_error_message(error.messsage))
        }
    }
}

/**
 * Delete express type
 */
export const action_delete_express_types = (id) => {
    return async (dispatch) => {
        dispatch(start_loading());
        try{
            const response = await informationservice.delete_express_type(id);
            if(checkStatus(response)){
                const body = await response.data;
                dispatch(action_fetch_express_types(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Successfully deleted ' + body.name,
                    type: 'success'
                }))
            }else{
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}

export const action_fetch_shipping_modes = (force = false) => {
    return async (dispatch, getState) => {
        const result = getState()?.information.shipping_modes || []
        /**
         * if data exist()
         * do nothing and return
         */
        if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getShippingMode(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_shipping_mode(body));
            }
            else dispatch(stop_loading());
        } catch (error) {
            dispatch(set_error_message(error.messsage));
        }
    }
}

export const action_delete_shipping_mode = (id) => {
    return async (dispatch) => {
        dispatch(start_loading());
        try{
            const response = await informationservice.delete_shipping_mode(id);
            if(checkStatus(response)){
                const body = await response.data;
                dispatch(action_fetch_shipping_modes(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Successfully deleted: '+body['data'].name,
                    type: 'success'
                }))
            }else{
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error){
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}

export const action_fetch_carriers = (force = false) => {
    return async (dispatch, getState) => {
        const result = getState()?.information.itemtypes || []
        /**
         * if data exist() 
         * do nothing and return
         */
        if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getCarriers();
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_carriers(body));
            }
            else dispatch(stop_loading());
        } catch (error) {
            dispatch(set_error_message(error.messsage))
        }
    }
}

export const action_fetch_payment_method = (force = false) => {
    return async (dispatch, getState) => {
        const result = getState()?.information.payment_methods || []
        /**
         * if data exist() 
         * do nothing and return
         */
        if (!force && Array.isArray(result) && result.length > 0) return;

        dispatch(start_loading());
        try {
            const response = await informationservice.getPaymentMethod(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_payment_methods(body));
            }
            else dispatch(stop_loading());
        } catch (error) {
            dispatch(set_error_message(error.messsage))
        }
    }
}

export const action_delete_payment_method = (id) => {
    return async (dispatch) => {
        try{
            const response = await informationservice.delete_payment_type(id);
            if(checkStatus(response)){
                const body = await response.data;
                dispatch(action_fetch_payment_method(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'successfully_created',
                    type: 'success'
                }))
            }else{
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error){
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}

export const action_fetch_problem_type = () => {
    return async (dispatch, getState) => {
        dispatch(start_loading());
        try {
            const response = await informationservice.getProblemTypes();
            if (checkStatus(response)) {
                const body = await response.data;
                console.table(body);
                dispatch(set_problem_types(body));
            }
            else dispatch(stop_loading());
        } catch (error) {
            dispatch(set_error_message(error.messsage))
        }
    }
}

export const action_fetch_collection_type = (force = false) => {
    return async (dispatch, getState) => {
        try {
            const response = await informationservice.get_collection_type(force);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(set_collection_type(body || []));
            }
        } catch (error) {
            dispatch(set_error_message(error.messsage));
        }
    }
}

export const action_create_collection_type = (data) => {
    return async (dispatch, getState) => {
        try {
            const response = await informationservice.create_collection_type(data);
            if (checkStatus(response)) {
                const body = await response.data;
                dispatch(action_fetch_collection_type(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'successfully_created',
                    type: 'success'
                }))
            } else {
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        } catch (error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}

export const action_fetch_currency = (force = false) =>{
    return async (dispatch) => {
        try{
            const response = await informationservice.getCurrency(force);
            if(checkStatus(response)){
                const body = await response.data;
                dispatch(set_currency(body));
            }else{
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error){
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))
        }
    }
}

export const action_create_currency = (data) => {
    return async (dispatch) => {
        try{
            const response = await informationservice.createCurrency(data);
            if(checkStatus(response)){
                const body = await response.data;
                dispatch(action_fetch_currency(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'successfully_created',
                    type: 'success'
                }))
            }else{
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            })) 
        }
    }
}

export const action_update_currency = (data) => {
    return async (dispatch) => {
        try{
            const response = await informationservice.updateCurrency(data);
            if(checkStatus(response)){
                const body = await response.data;
                dispatch(action_fetch_currency(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'successfully_created',
                    type: 'success'
                }))
            }else{
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            })) 
        }
    }
}

export const action_delete_currency = (id) => {
    return async (dispatch) => {
        try{
            const response = await informationservice.deleteCurrency(id);
            if(checkStatus(response)){
                const body = await response.data;
                dispatch(action_fetch_currency(true));
                dispatch(component_reducer.set_snack_bar_content({
                    message: body.message || 'successfully_created',
                    type: 'success'
                }))
            }else{
                dispatch(component_reducer.set_snack_bar_content({
                    message: 'Oop something went wrong!',
                    type: 'error'
                }))
            }
        }catch(error) {
            const size = (Array.isArray(error.errors) && error.errors.length > 0) ? error.errors.length : null
            dispatch(component_reducer.set_snack_bar_content({
                message: error?.message || (
                    size ? error?.errors[size - 1].msg
                        :
                        'Oop something went wrong!'
                ),
                type: 'error'
            }))  
        }
    }
}