import _ from 'lodash'
import * as common from './type';

export const initialState = [];

/**
 * @param reducer
 */

const Index = (state = initialState, action) => {

    const { payload } = action

    switch (action.type) {
        case common.ADD_TAB:

            if (!payload?.name || !payload?.url)
                return state;

            const isExist = state.filter(tab => tab?.name === payload?.name)

            if (isExist.length > 0) return state;
            return [...state, payload];

        case common.DEL_TAB:
            if (!isNaN(payload) && payload < state.length) {
                const cloneArr = _.cloneDeep(state)
                cloneArr.splice(payload, 1);
                return cloneArr;
            }
            else
                return state
        default:
            return state;
    }
}

export default Index;

export const addTab = (tab = {}) => ({
    type: common.ADD_TAB,
    payload: tab
})

export const delTab = (tab) => ({
    type: common.DEL_TAB,
    payload: tab
})