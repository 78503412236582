import { getPaginationQuery, httpService } from ".";

export async function getCollectionOption() {
  return httpService.get(`/collection-type/get-option`);
}

export async function get_waybill_to_make_purchase(data) {
  return httpService.post(
    `/waybill/get-waybill-for-purchase?${getPaginationQuery()}&total=true`,
    data
  );
}

export async function get_transcation_count(waybillnumber) {
  return httpService.get(
    `/transaction/get-transcation-count?waybillnumber=${waybillnumber}`
  );
}

export async function export_finance(data) {
  return await httpService.post(
    "/waybill/get-waybill-for-finance-export",
    { payment_group: data },
    {
      responseType: "blob",
    }
  );
}

/**
 * waybill for sale list and export
 * @param {*} data
 * @returns
 */
export async function get_waybill_for_sale(data) {
  return httpService.post(
    `/waybill/get-for-sale?${getPaginationQuery()}`,
    data
  );
}

export async function export_finance_for_sale(data) {
  return await httpService.post(
    "/waybill/export-for-sale",
    { payment_group: data },
    {
      responseType: "blob",
    }
  );
}

export async function export_finance_record() {
  return await httpService.get("/transaction/csv-export", {
    responseType: "blob",
  });
}

export async function make_purchase(data) {
  return httpService.post(`/transaction/make-purchase`, data);
}

export async function get_transaction(force, data) {
  return httpService.get(
    `/transaction/get-all${
      force ? "-refresh" : ""
    }?${getPaginationQuery()}&total=true${
      data === {} ? "" : `&${new URLSearchParams(data).toString()}`
    }`
  );
}

/**
 * Search cash and credit waybill
 */
export async function search_waybill_to_make_purchase(data) {
  return await httpService.post("waybill/search-waybill-for-finance", data);
}

/**
 * Search transcation
 */
// export async function search_transaction(data){
//     return await httpService.post('/transaction/search',data);
// }
