export const types = {
     SET_INDEX: 'SET_INDEX',
     SET_WAYBILL_LIST: 'SET_WAYBILL_LIST',
     START_LOADING: 'START_LOADING',
     STOP_LOADING: 'STOP_LOADING',
     SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE'
}

const initialState = {
     index: 0,
     waybill_list: [
          {
               waybillnumber: 'EF123456',
               pickup_date: 'Tue 9 November, 2021',
               status_list: [
                    {
                         status: 'Delivered',
                         date: 'Tue 9 November, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
                    {
                         status: 'Delivered',
                         date: 'Tue 9 November, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
                    {
                         status: 'Delivered',
                         date: 'Tue 9 November, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
                    {
                         status: 'Delivered',
                         date: 'Tue 9 November, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
               ]
          },
          {
               waybillnumber: 'EF999999',
               pickup_date: 'Tue 8 November, 2021',
               status_list: [
                    {
                         status: 'Delivered',
                         date: 'Tue 8 November, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
                    {
                         status: 'Delivered',
                         date: 'Tue 8 November, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
                    {
                         status: 'Delivered',
                         date: 'Tue 8 November, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
                    {
                         status: 'Delivered',
                         date: 'Tue 8 November, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
               ]
          },
          {
               waybillnumber: 'EF111111',
               pickup_date: 'Tue 7 October, 2021',
               status_list: [
                    {
                         status: 'Delivered',
                         date: 'Tue 7 October, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
                    {
                         status: 'Delivered',
                         date: 'Tue 7 October, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
                    {
                         status: 'Delivered',
                         date: 'Tue 7 October, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
                    {
                         status: 'Delivered',
                         date: 'Tue 7 October, 2021',
                         message: 'Your order have been delivered from China Yunnan warehouse. Your order have arrived. Your order have arrived'
                    },
               ]
          }
     ],
     isLoading: false,
     errorMessage: ''
}

const _getCommonState = (state) => ({
     ...state,
     isLoading: false,
     errorMessage: '',
     // showEditDialog: false
})

/**
 * Reducer
 */

const Reducer = (state = initialState, action) => {
     switch (action.type) {
          case types.SET_INDEX:
               return {
                    ..._getCommonState(state),
                    index: action.payload
               }
          case types.SET_WAYBILL_LIST:
               return {
                    ..._getCommonState(state),
                    waybill_list: action.payload
               }
          case types.START_LOADING:
               return {
                    ..._getCommonState(state),
                    isLoading: true
               }
          case types.STOP_LOADING:
               return {
                    ..._getCommonState(state),
                    isLoading: false
               }
          case types.SET_ERROR_MESSAGE:
               return {
                    ..._getCommonState(state),
                    errorMessage: action.payload
               }

          default:
               return state;
     }
}

export default Reducer;

/**
 * Action
 */
export const setIndex = (index) => ({
     type: types.SET_INDEX,
     payload: index
});

export const setWaybillList = (data = []) => ({
     type: types.SET_WAYBILL_LIST,
     payload: data
})

export const startLoading = () => ({
     type: types.START_LOADING,
})

export const stopLoading = () => ({
     type: types.STOP_LOADING,
})

export const setErrorMessage = (message = '') => ({
     type: types.SET_ERROR_MESSAGE,
     payload: message
})