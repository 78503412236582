import * as common from "../type";
import _ from "lodash";

const types = {
  /**
   * for two table data
   */

  SET_FIRST_ACTION_ROW: "SET_FIRST_ACTION_ROW",
  SET_SENCOND_ACTION_ROW: "SET_SENCOND_ACTION_ROW",

  SET_ABLE_TO_CONFIRM: "SET_ABLE_TO_CONFIRM",

  /**
   * change toggle status
   */
  UPDATE_ITEM_STATUS: "UPDATE_ITEM_STATUS",
  UPDATE_STATUS_TO_ALL_ITEM: "UPDATE_STATUS_TO_ALL_ITEM",

  /**
   * change progess status to item
   */
  UPDATE_PROGRESS_STATUS: "UPDATE_PROGRESS_STATUS",

  REFRESH_TABLE: "REFRESH_TABLE",
};

export const initialState = {
  isLoading: false,
  errorMessage: "",

  first_table_rows: [],
  second_table_rows: [],

  able_to_confirm: false,
  refresh_table: false,
};

const _getCommonState = (state) => ({
  ...state,
  isLoading: false,
  errorMessage: "",
});

const Index = (state = initialState, action) => {
  switch (action.type) {
    case common.START_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: true,
      };
    case common.STOP_LOADING:
      return {
        ..._getCommonState(state),
      };
    case common.SET_ERROR_MESSAGE:
      return {
        ..._getCommonState(state),
        errorMessage: action.payload,
      };

    case types.REFRESH_TABLE:
      return {
        ..._getCommonState(state),
        refresh_table: action.payload,
      };

    /**
     * set inventory item
     */
    case types.SET_FIRST_ACTION_ROW:
      return {
        ..._getCommonState(state),
        first_table_rows: action.payload,
      };
    /**
     * set loading item
     */
    case types.SET_SENCOND_ACTION_ROW:
      return {
        ..._getCommonState(state),
        second_table_rows: action.payload,
      };

    /**
     * update status by index and field
     */
    case types.UPDATE_ITEM_STATUS:
      try {
        const { index, status, field } = action;

        if (!field)
          throw new Error("Invalid field for cargo action waybill list");

        const dump = _.cloneDeep(state[field]) || [];
        let dump_item = _.cloneDeep(dump[index]);

        dump_item = {
          ...dump_item,
          checked: status,
        };

        dump[index] = dump_item;

        const temp = {
          ..._getCommonState(state),
        };

        temp[field] = dump;

        return temp;
      } catch (error) {
        return {
          ..._getCommonState(state),
        };
      }

    /**
     * update check [true,false] status to all item when check to item of table
     */
    case types.UPDATE_STATUS_TO_ALL_ITEM:
      try {
        const { status, field } = action;

        if (!field)
          throw new Error("Invalid field for cargo action waybill list");

        const dump = _.cloneDeep(state[field]) || [];

        if (!Array.isArray(dump)) return { ..._getCommonState(state) };

        if (dump.length === 0) return { ..._getCommonState(state) };

        let updated_list = dump.map((d) => ({
          ...d,
          checked: status,
        }));

        const temp = {
          ..._getCommonState(state),
        };

        temp[field] = updated_list;

        return temp;
      } catch (error) {
        return {
          ..._getCommonState(state),
        };
      }

    /**
     * update progress status to all item after confirmation is success
     */
    case types.UPDATE_PROGRESS_STATUS:
      try {
        const { status, field } = action;

        if (!field)
          throw new Error("Invalid field for cargo action waybill list");

        const dump = _.cloneDeep(state[field]) || [];

        if (!Array.isArray(dump)) return { ..._getCommonState(state) };

        if (dump.length === 0) return { ..._getCommonState(state) };

        let updated_list = dump.map((d) => ({
          ...d,
          currentStatus: status,
        }));

        const temp = {
          ..._getCommonState(state),
        };

        temp[field] = updated_list;

        return temp;
      } catch (error) {
        return {
          ..._getCommonState(state),
        };
      }

    case types.SET_ABLE_TO_CONFIRM:
      const { status, from, to } = action;
      if (status)
        return {
          ..._getCommonState(state),
          able_to_confirm: true,
        };
      else {
        const { first_table_rows, second_table_rows } = state;

        const first_temp = _.cloneDeep(first_table_rows).map((r) => ({
          ...r,
          currentStatus: from,
        }));

        const second_temp = _.cloneDeep(second_table_rows).map((r) => ({
          ...r,
          currentStatus: to,
        }));

        return {
          ..._getCommonState(state),
          able_to_confirm: false,
          first_table_rows: first_temp,
          second_table_rows: second_temp,
        };
      }

    default:
      return state;
  }
};

export default Index;

/**
 * action
 */

export const start_loading = () => ({
  type: common.START_LOADING,
});

export const stop_loading = () => ({
  type: common.STOP_LOADING,
});

export const set_error_message = (message = "") => ({
  type: common.SET_ERROR_MESSAGE,
  payload: message,
});

/**
 * set inventory item
 */
export const set_first_table_row = (data = []) => ({
  type: types.SET_FIRST_ACTION_ROW,
  payload: data,
});

/**
 * set loading item
 */
export const set_second_table_row = (data = []) => ({
  type: types.SET_SENCOND_ACTION_ROW,
  payload: data,
});

/**
 * update status
 */

/** to each item by index */
export const update_item_status = (index, status, field) => ({
  type: types.UPDATE_ITEM_STATUS,
  index,
  status,
  field,
});

/** to each item by index */
export const update_status_to_all_item = (status, field) => ({
  type: types.UPDATE_STATUS_TO_ALL_ITEM,
  status,
  field,
});

/**
 * update progress status to item
 */
export const update_progress_status = (status, field) => ({
  type: types.UPDATE_PROGRESS_STATUS,
  status,
  field,
});

export const set_able_to_confirm_status = (status, from, to) => ({
  type: types.SET_ABLE_TO_CONFIRM,
  status: status,
  from: from,
  to: to,
});

export const start_table_refresh = () => ({
  type: types.REFRESH_TABLE,
  payload: true,
});

export const stop_table_refresh = () => ({
  type: types.REFRESH_TABLE,
  payload: false,
});
